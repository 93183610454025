import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p"><strong parentName="em">{`NOTE: Now that the webinar has happened, you can view the presentation or listen to the audio recording of the webinar from this link `}<a parentName="strong" {...{
            "href": "https://www.stlouisfed.org/bsr/EI_CDAudioConference/index.cfm?proc=call&act=view&sid=16"
          }}>{`here`}</a>{`.`}</strong></em></p>
    <p>{`Register for what will be an interesting webinar hosted by the `}<a parentName="p" {...{
        "href": "http://www.stlouisfed.org/"
      }}>{`Federal Reserve Bank of St. Louis`}</a>{` featuring PayPerks CEO `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/arlyndavich"
      }}>{`Arlyn Davich`}</a>{`.`}</p>
    <p>{`The webinar, `}<strong parentName="p">{`Innovations that Enhance Financial Capability and Engagement`}</strong>{`, will be held from `}<strong parentName="p">{`2-3:15 PM Central Time on October 2nd`}</strong>{`. It is free to attend but requires an `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://www.cvent.com/d/v4q56h/4W"
        }}>{`RSVP here`}</a></strong>{`.`}</p>
    <p>{`Arlyn has been invited by the Fed to participate along with PayPerks friends and colleagues Joshua Sledge, Innovation Labs Manager at the `}<a parentName="p" {...{
        "href": "http://www.cfsinnovation.com/"
      }}>{`Center for Financial Services Innovation`}</a>{` and Nick Maynard, Senior Innovation Director at the `}<a parentName="p" {...{
        "href": "http://www.d2dfund.org/"
      }}>{`Doorways to Dreams (D2D Fund)`}</a>{`.`}</p>
    <p>{`The discussion will focus on how to translate financial knowledge into financial empowerment and greater access among low- and moderate-income individuals.`}</p>
    <p>{`Learn more about how Arlyn can up with the idea, what's in the PayPerks secret sauce, and some of our recent successes on `}<a parentName="p" {...{
        "href": "http://paybefore.com/op-ed/blog-banking-the-unbanked-with-direct-express/"
      }}>{`DirectExpress`}</a>{` and other prepaid programs.`}</p>
    <p>{`For more information on the event, including information how how to submit questions to the participants, check out the event summary: `}<a parentName="p" {...{
        "href": "http://www.cvent.com/d/v4q56h"
      }}>{`http://www.cvent.com/d/v4q56h`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      